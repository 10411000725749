<template>
  <div class="container">
    <div class="header">
      <div class="graphic-title">
        {{ title }}
      </div>
    </div>

    <div
      v-if=" chart_options && series.length > 0"
      class="my-5 graphic-card"
    >
      <div
        id="chart"
        class="chart-container"
      >
        <apexchart
          class="d-flex justify-center"
          type="donut"
          :options="chart_options"
          :series="series"
          width="250px"
          height="250px"
        />
      </div>
    </div>
    <div
      v-else
      class="justify-center d-flex align-center"
      style="height: 200px"
    >
      <h3>Data Tidak Ditemukan</h3>
    </div>
    <div class="mt-2 d-flex flex-column">
      <span style="font-size: 13px; font-weight: 600;">Fasyankes Mengisi/Total Fasyankes:</span>
      <span style="color: #31B4CC; font-size: 14px; font-weight: 600;">{{ summaryData.report_total_fillings }} / {{ summaryData.total }}</span>
      <span
        class="mt-1"
        style="font-size: 11px;"
      >Pembaharuan Terakhir</span>
      <span style="font-size: 11px;">{{ summaryData.report_last_update ? formatDate(summaryData.report_last_update) : '-' }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COLORS } from "../../../constants/colors";

export default {
  name: "GraphicHomeDonutEnvironmentalHealthKey",
  props: {
    summaryData: {
      type: [Array, Object],
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    series: {
      type: Array,
      default: () => [],
    },
    provinceName: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      COLORS,
      chart_options: {
        chart: {
          fontFamily: "Montserrat",
          type: "donut",
          title: this.title,
        },
        series: this.series,
        labels: ["Sesuai Kriteria", "Tidak Sesuai Kriteria"],
        colors: [COLORS.primary_blue, COLORS.muted_gray],
        plotOptions: {
          pie: {
            donut: {
              // size: "65%",
              labels: {
                show: false,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: COLORS.primary_blue,
                  offsetY: -10,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
      },
      dialog_print: false,
      print_title: "",
    };
  },
  computed: {
    ...mapState({
      summary: (state) => state.report.monthly_summary,
    }),
  },

  methods: {
    formatDate(dateString) {
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Date(dateString).toLocaleDateString("id", options);
    },
    print() {
      if (this.chart_options) {
        if (this.chart_options.legend.width) {
          delete this.chart_options.legend.width;
        }

        this.print_title = `${
          this.provinceName ? this.provinceName : "Nasional"
        } - ${this.$date(this.date).format("MMMM YYYY")}`;

        this.dialog_print = true;
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 0px;
}

.chart-container {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
}

.graphic-title {
  /* margin: 20px; */
  margin-left: 10px;
  font-weight: 525;
}

.graphic-card {
  background: #fff;
  border-radius: 13px;
}

.button-print {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-print:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>