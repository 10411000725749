<template>
  <div>
    
    <!-- ===========================================pengelolaan====================================== -->
    <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'Pengelolaan'"
    >
      <v-tooltip
        color="white"
        max-width="300px"
        nudge-top="70px"
        nudge-left="100px"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-1 pointer"
            small
            color="blue"
            v-bind="attrs"
            v-on="on"
            >mdi-information</v-icon
          >
        </template>
        <div>
          <p class="ma-0 small_txt color_txt mb-1">
            Standar Pengelolaan Limbah
          </p>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Sesuai Standar (
                <div class="yes"></div>
                ) :</b
              >
              memenuhi 5 indikator dalam pengelolaan limbah yaitu; pemilahan,
              pewadahan, pengangkutan, penyimpanan, dan pengolahan
            </p>
          </div>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Belum Sesuai Standar (
                <div class="no"></div>
                ) :</b
              >
              Tidak memenuhi salahsatu atau lebih dari 5 indikator dalam
              pengelolaan limbah
            </p>
          </div>
        </div>
      </v-tooltip>
      <p class="ma-0 small_txt">Standar pengelolaan limbah</p>
    </div>

    <!-- ===========================================pemilihan====================================== -->
    <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'Pemilahan'"
    >
      <v-tooltip
        color="white"
        max-width="300px"
        nudge-top="70px"
        nudge-left="100px"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-1 pointer"
            small
            color="blue"
            v-bind="attrs"
            v-on="on"
            >mdi-information</v-icon
          >
        </template>
        <div>
          <p class="ma-0 small_txt color_txt mb-1">
            Pemilihan Limbah Fasyankes
          </p>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Dilakukan Pemilahan (
                <div class="yes"></div>
                ) :</b
              >
              limbah dipilah menjadi limbah domestik dan limbah medis
            </p>
          </div>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >tidak dilakukan Pemilahan (
                <div class="no"></div>
                ) :</b
              >
              limbah tidak dipilah menjadi limbah domestik dan limbah medis
            </p>
          </div>
        </div>
      </v-tooltip>
      <p class="ma-0 small_txt">Pemilahan Limbah Fasyankes</p>
    </div>

    <!-- ===========================================pewadahan====================================== -->
    <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'Pewadahan'"
    >
      <v-tooltip
        color="white"
        max-width="300px"
        nudge-top="70px"
        nudge-left="100px"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-1 pointer"
            small
            color="blue"
            v-bind="attrs"
            v-on="on"
            >mdi-information</v-icon
          >
        </template>
        <div>
          <p class="ma-0 small_txt color_txt mb-1">
            Spesifikasi pewadahan plastik
          </p>
          <div>
            <p class="ma-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Sesuai Kriteria (
                <div class="yes"></div>
                ) :</b
              >
              memenuhi semua kriteria
            </p>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Tertutupi</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Tidak bocor</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Label sesuai</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Simbol sesuai</p>
            </div>
          </div>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Tidak sesuai kriteria (
                <div class="no"></div>
                ) :</b
              >
              tidak memenuhi kriteria
            </p>
          </div>
        </div>
      </v-tooltip>
      <p class="ma-0 small_txt">Spesifikasi pewadahan plastik</p>
    </div>

    <!-- ===========================================pengangkutan====================================== -->
    <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'Pengangkutan'"
    >
      <v-tooltip
        color="white"
        max-width="300px"
        nudge-top="70px"
        nudge-left="30px"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-1 pointer"
            small
            color="blue"
            v-bind="attrs"
            v-on="on"
            >mdi-information</v-icon
          >
        </template>
        <div>
          <p class="ma-0 small_txt color_txt mb-1">
            Spesifikasi Pengangkutan ke tempat penyimpanan
          </p>
          <div>
            <p class="ma-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Sesuai Kriteria (
                <div class="yes"></div>
                ) :</b
              >
              memenuhi semua kriteria
            </p>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">alat angkut khusus limbah</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Wheel/bin wadah beroda</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Tertutup</p>
            </div>
            <div class="d-flex align-center">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">Tidak bocor</p>
            </div>
          </div>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Tidak sesuai kriteria (
                <div class="no"></div>
                ) :</b
              >
              tidak memenuhi kriteria
            </p>
          </div>
        </div>
      </v-tooltip>
      <p class="ma-0 small_txt text-center">
        Spesifikasi Pengangkutan ke tempat penyimpanan
      </p>
    </div>

    <!-- ===========================================penyimpanan====================================== -->
    <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'Penyimpanan'"
    >
      <v-tooltip
        color="white"
        max-width="350px"
        nudge-top="70px"
        nudge-left="100px"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-1 pointer"
            small
            color="blue"
            v-bind="attrs"
            v-on="on"
            >mdi-information</v-icon
          >
        </template>
        <div>
          <p class="ma-0 small_txt color_txt mb-1">
            Ketersediaan ruang penyimpanan
          </p>
          <div>
            <p class="ma-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Sesuai Kriteria (
                <div class="yes"></div>
                ) :</b
              >
              memenuhi semua kriteria
            </p>
            <div class="d-flex">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">
                Di Tempat Penyimpanan Sementara(TPS) limbah B3 berizin(Dalam
                area Fasysankes)
              </p>
            </div>
            <div class="d-flex">
              <v-icon small color="blue" class="mr-1"
                >mdi-checkbox-marked-outline</v-icon
              >
              <p class="ma-0 small_txt color_txt">
                Di Tempat Penyimpanan Sementara(TPS) limbah B3 depo pemindahan
                berizin(Diluar area Fasysankes)
              </p>
            </div>
          </div>
          <div>
            <p class="a-0 small_txt color_txt">
              <b class="small_txt d-flex align-center"
                >Tidak sesuai kriteria (
                <div class="no"></div>
                ) :</b
              >
              tidak memenuhi kriteria
            </p>
          </div>
        </div>
      </v-tooltip>
      <p class="ma-0 small_txt">Ketersediaan ruang penyimpanan</p>
    </div>

    <!-- ===========================================pengolahan====================================== -->
    <!-- <div
      class="d-flex justify-center align-center"
      v-if="selectedFasyankes == 'pengolahan'"
    >
      <v-icon class="mr-1" small color="blue">mdi-information</v-icon>
      <p class="ma-0 small_txt">Spesifikasi pengolahan limbah</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "tooltipfasyankes",
  props: {
    selectedFasyankes: {
      type: String & Array & Object,
      required: true,
    },
    
  },
  data() {
      return {
          data : {
              
          }
      }
  }
  
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.yes {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background: #59b5cc;
}
.no {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background: #28577a;
}
</style>