<template>
  <div v-if="$isMobile()">
    <div class="summary_data">
      <div class="d-flex">
        <GrafikBeranda
          ref="grafik"
          :date.sync="date"
          :tab_id.sync="tab_id"
        />
      </div>
    </div>
    <div
      v-if="showMarker"
      class="absolute_info_mark"
    >
      <v-card class="rounded-max custom_card pa-3 card_info_mark">
        <v-row>
          <v-col cols="6">
            <div class="d-flex justify-center align-center">
              <img
                src="@/assets/img/marker_blue.svg"
                height="30px"
                alt=""
              >
              <p class="ml-2 ma-0">
                Rumah Sakit
              </p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center">
              <img
                src="@/assets/img/marker_red.svg"
                height="30px"
                alt=""
              >
              <p class="ml-2 ma-0">
                Puskesmas
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div>
      <modal-notif
        :dialog-notif="dialogNotif"
        @close="dialogNotif = false"
      />
    </div>
    <div
      id="mapContainer"
      style="display: none"
      class="maps"
    />
  </div>

  <div v-else>
    <div class="summary_data">
      <div class="custom_tab pa-2 d-flex">
        <GrafikBeranda
          ref="grafik"
          :date.sync="date"
          :tab_id.sync="tab_id"
        />
      </div>
    </div>

    <div class="absolute_search">
      <div class="temp_input_src d-flex">
        <div style="position: relative">
          <div class="d-flex mr-3">
            <input
              v-model="findReg"
              type="text"
              :placeholder="`Cari ${mapLevel}`"
              class="custom_search"
              @keyup="getIdRegion"
            >
            <v-icon
              v-if="isFinding"
              class="clear_input"
              small
              @click="clearInput"
            >
              mdi-close
            </v-icon>
          </div>
          <v-card
            v-if="isFinding"
            class="list_reg pa-1 mt-2"
            flat
            width="200px"
          >
            <div v-if="!loadSearch && listReg">
              <div v-if="mapLevel == 'provinsi'">
                <div
                  v-for="(item, idx) in listReg"
                  :key="`map-${idx}`"
                  class="list_item pa-3"
                  @click="selectListreg(item)"
                >
                  <p class="small_txt color_txt pa-0 ma-0">
                    {{ item.provinsi_name }}
                  </p>
                </div>
              </div>
              <div v-if="mapLevel == 'kota'">
                <div
                  v-for="(item, idx) in listReg"
                  :key="`map-${idx}`"
                  class="list_item pa-3"
                  @click="selectListreg(item)"
                >
                  <p class="small_txt color_txt pa-0 ma-0">
                    {{ item.kabupaten_name }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="!loadSearch && !listReg.length">
              <p class="small_txt color_txt pa-3 ma-0">
                '{{ findReg }}' Tidak ditemukan
              </p>
            </div>
            <div
              v-if="loadSearch"
              class="pa-3 d-flex justify-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                size="30"
              />
            </div>
          </v-card>
        </div>
        <v-btn
          dark
          color="primary"
          depressed
          min-width="5px"
          @click="searchCoordinate"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          v-if="needReset"
          dark
          color="primary"
          depressed
          class="ml-2"
          min-width="5px"
          @click="resetMap(true)"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-if="showMarker"
      class="absolute_info_mark"
    >
      <v-card class="rounded-max custom_card pa-3 card_info_mark">
        <v-row>
          <v-col cols="6">
            <div class="d-flex justify-center align-center">
              <img
                src="@/assets/img/marker_blue.svg"
                height="30px"
                alt=""
              >
              <p class="ml-2 ma-0">
                Rumah Sakit
              </p>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-center">
              <img
                src="@/assets/img/marker_red.svg"
                height="30px"
                alt=""
              >
              <p class="ml-2 ma-0">
                Puskesmas
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div class="absolute_fade">
      <div class="temp_fade d-flex">
        <div class="separate">
          <div class="fade1 pa-2" />
          <p class="absolute_p">
            NA
          </p>
        </div>
        <div class="separate">
          <div class="fade2 pa-2" />
          <p class="absolute_p">
            0%
          </p>
        </div>
        <div class="separate">
          <div class="fade3 pa-2" />
          <p class="absolute_p">
            25%
          </p>
        </div>
        <div class="separate">
          <div class="fade4 pa-2" />
          <p class="absolute_p">
            50%
          </p>
        </div>
        <div class="separate">
          <div class="fade5 pa-2" />
          <p class="absolute_p">
            75%
          </p>
        </div>
        <div class="separate">
          <div class="fade6 pa-2" />
          <p class="absolute_p">
            100%
          </p>
        </div>
      </div>
    </div>
    <template>
      <div
        id="mapContainer"
        class="maps"
      />
    </template>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { mapState } from "vuex";
import kota from "../../../assets/geojson/kab_kota_indonesia.geojson";
import provinsi from "../../../assets/geojson/prov_indonesia.geojson";
import GrafikBeranda from "../../Grafik/Beranda/grafikBeranda.vue";
import modalNotif from "../../Notification/modalNotif.vue";

export default {
  name: "PublicHome",
  components: { modalNotif, GrafikBeranda },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SAVE_MONTH", null);
    this.$store.commit("SAVE_YEAR", null);

    next();
  },
  data() {
    return {
      loading: false,
      tab_id: 1,
      date: this.$date().format("YYYY-MM"),
      map_values_province: {},
      colors: [
        "#C2FDF0",
        "#7DF1D7",
        "#69D4D6",
        "#59B5CC",
        "#4081BA",
        "#28577A",
      ],
      map_provinces_color: null,
      map_regencies_color: null,
      findReg: "",
      isFinding: false,
      selectedIdreg: null,
      listReg: null,
      loadSearch: false,
      dialogNotif: false,
      markerRed: require("@/assets/img/marker_red.svg"),
      markerBlue: require("@/assets/img/marker_blue.svg"),
      dataGeo: provinsi,
      city: kota,
      kab: kota,
      map: null,
      idProv: null,
      default_coordinate: {
        lat: -2.071825,
        lng: 121.75252,
      },
      needReset: false,
      showMarker: false,
      items: ["Fasyankes", "Rumah Sakit", "Puskesmas"],
      type: "Fasyankes",
      type2: "Pengelolaan",
      sameId: [],
      mapLevel: "provinsi",
      items2: [
        "Pengelolaan",
        "Pemilahan",
        "Pewadahan",
        "Pengangkutan",
        "Penyimpanan",
        "Pengholahan",
        "Timbulan Limbah Medis",
      ],
      series: [60, 40],
      mkpuskesmas: [],
      chartOptions: {
        colors: ["#59B5CC", "#28577A"],
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        legend: {
          position: "bottom",
        },

        labels: ["Sesuai Standar", "Belum Sesuai Standar"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      savedMonth: (state) => state.savedMonth,
      savedYear: (state) => state.savedYear,
      monthly_summary: (state) => state.report.monthly_summary,
      waste_monthly_summary: (state) => state.report.waste_monthly_summary,
    }),
  },
  watch: {
    date(newValue) {
      this.getMapColors(null, null, newValue);
    },
    tab_id(newValue) {
      this.resetMap(true);
      this.getMapColors(null, null, this.date);
    },
  },
  mounted() {
    this.getMapColors();

    if (this.$route.name == "Home") {
      if (this.role == "Fasyankes") {
        this.dialogNotif = true;
      }
    }
  },
  methods: {
    getMapColors(province_id, map, date) {
      this.loading = true;

      let request_params = {
        with_achievements: true,
      };
      if (province_id) {
        request_params.province_id = province_id;
      }
      if (date) {
        request_params.date = date;
      }
      let dispatcher_key = "";
      if (this.tab_id == 1) {
        dispatcher_key = "getMonthlySummary";
      } else if (this.tab_id == 2) {
        dispatcher_key = "getWasteManagementMonthlySummary";
      } else {
        dispatcher_key = "getMonthlySummary";
      }

      this.$store
        .dispatch(`report/${dispatcher_key}`, request_params)
        .then((res) => {
          if (province_id) {
            this.map_values_regencies = this.generateMapValues(
              "kota",
              res.achievements
            );
            this.map_regencies_color = res.achievements.map((achievement) => {
              const color = this.getLegendColor(
                achievement.total_facilitator_with_report_percentage
              );
              return {
                id: achievement.kabupatenKota_id,
                color,
              };
            });
          } else {
            this.map_values_province = this.generateMapValues(
              "provinsi",
              res.achievements
            );
            this.map_provinces_color = res.achievements.map((achievement) => {
              const color = this.getLegendColor(
                achievement.total_facilitator_with_report_percentage
              );
              return {
                id: achievement.provinsi_id,
                color,
              };
            });
          }

          if (this.mapLevel == "provinsi") {
            this.resetMap(false);
          } else {
            this.addColor("prov", province_id, map);
          }
        });
      this.loading = false;
    },
    getLegendColor(percentage) {
      if (percentage == 0) {
        return "#C4CFD9";
      } else if (percentage < 25) {
        return "#DBE37B";
      } else if (percentage == 25 || percentage < 50) {
        return "#96E37B";
      } else if (percentage == 50 || percentage < 75) {
        return "#23DDBC";
      } else if (percentage == 75 || percentage < 100) {
        return "#37BDD6";
      } else if (percentage >= 100) {
        return "#0092E4";
      } else {
        return "#C4CFD9";
      }
    },
    generateMapValues(map_level, achievements) {
      const map = {};
      if (map_level == "provinsi") {
        achievements.forEach((achievement) => {
          map[achievement.provinsi_id] =
            achievement.total_facilitator_with_report_percentage;
        });
      } else if (map_level == "kota") {
        achievements.forEach((achievement) => {
          map[achievement.kabupatenKota_id] =
            achievement.total_facilitator_with_report_percentage;
        });
      }

      return map;
    },
    getIdRegion() {
      this.loadSearch = true;
      let data = {
        type: this.mapLevel,
        find: this.findReg,
        idProv: this.idProv,
      };
      this.isFinding = true;
      this.$store.dispatch("region/searchRegion", data).then((data) => {
        this.listReg = data.data;
        this.loadSearch = false;

        if (this.findReg == "") {
          this.clearInput();
        }
      });
    },
    selectListreg(item) {
      if (this.mapLevel == "provinsi") {
        this.findReg = item.provinsi_name;
        this.selectedIdreg = item.provinsi_id;
        this.idProv = item.provinsi_id;
      } else {
        this.findReg = item.kabupaten_name;
        this.selectedIdreg = item.kabupatenkota_id;
      }
      this.isFinding = false;
    },
    clearInput() {
      this.isFinding = false;
      this.findReg = "";
    },
    searchCoordinate() {
      let selected_feature;
      if (this.mapLevel == "provinsi") {
        selected_feature = this.dataGeo.features.find((feature) => {
          return feature.properties.PROVNO == this.selectedIdreg;
        });
      } else if (this.mapLevel == "kota") {
        // province_id = 2 digit first of the id
        const province_id = this.selectedIdreg.toString().substring(0, 2);
        // regency_id = 2 digit last of the id, also remove if there's 0
        const regency_id = this.selectedIdreg
          .toString()
          .substring(2, 4)
          .replace("0", "");
        console.log(province_id, regency_id)

        selected_feature = this.dataGeo.features.find((feature) => {
          return (
            feature.properties.PROVNO == province_id &&
            feature.properties.KABKOTNO == regency_id
          );
        });
        
      }
      
      // calculate the center of the feature
      let bounds = L.geoJSON(selected_feature).getBounds();
      let center = bounds.getCenter();
      
      // fire click event on the selected_feature, point to the center of the feature
      this.clickable({
        latlng: {
          lat: center.lat,
          lng: center.lng,
        },
        layer: {
          feature: selected_feature,
        },
      });
    },
    resetMap(reset) {
      if (reset) {
        this.dataGeo = provinsi;
        this.needReset = false;
        this.showMarker = false;
        this.$refs.grafik.resetGrafik();
      }
      this.mapLevel = "provinsi";
      this.addColor(false);
      this.initMap(this.default_coordinate, 5, 1);
      this.clearInput();
    },
    // MENAMBAHK WARNA PADA PETA
    addColor(type, idx, map) {
      if (type == "prov") {
        // ========== KETIKA USER KLIK PROVINSI MAKA KOTANYA DI WARNAI
        let mutated_id = null;
        let final_id = null;
        this.dataGeo.features.forEach((el) => {
          let id = el.properties.KABKOTNO;
          if (id.toString().length > 1) {
            mutated_id = id;
          } else {
            mutated_id = `0${id}`;
          }
          final_id = `${idx}${mutated_id}`;
          this.map_regencies_color.forEach((i) => {
            if (el.properties.PROVNO == idx && final_id == i.id) {
              el["color"] = i.color;
            } else if (el.properties.PROVNO !== idx && final_id !== i.id) {
              el["color"] = "#C4CFD9";
            }
          });
        });
        this.initMap(map, 8, 1);
      } else if (type == "city") {
        // ========== KETIKA USER KLIK KOTA MAKA KOTA YANG DI KLIK DI KASIH WARNA
        this.dataGeo.features.forEach((el) => {
          if (
            el.properties.KABKOTNO == idx &&
            el.properties.PROVNO == this.idProv
          ) {
            el["color"] = "#69D4D6";
          } else {
            el["color"] = "none";
          }
        });
      } else {
        // ========== LOAD PERTAMAKALI
        this.dataGeo.features.forEach((el) => {
          this.map_provinces_color.forEach((i) => {
            if (el.properties.PROVNO == i.id) {
              el["color"] = i.color;
            }
          });
          // el["color"] =
          //   this.colors[Math.floor(Math.random() * this.colors.length)];
        });
      }
    },
    switchData(idx) {
      this.tab = idx;
    },
    /*
    parameter initMap() :
    coor: koordinat
    view: zoom in zoom out
    opc: opacity warna map
    */
    initMap(coordinates, view, opc) {
      console.log("initMap");
      if (this.map) {
        this.map.remove();
      }

      if (this.$isMobile()) {
        return;
      }

      this.map = L.map("mapContainer").setView(
        [coordinates.lat, coordinates.lng],
        view
      );
      L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);

      let vm = this;

      L.geoJSON(vm.dataGeo.features, {
        onEachFeature: this.onEachFeature,
        style(idx) {
          return {
            color: "white",
          };
        },
      })
        .addTo(this.map)
        .on("click", this.clickable);
    },
    onEachFeature(feature, layer) {
      const vm = this;
      const province_name = feature.properties.PROVINSI;
      const province_id = parseInt(feature.properties.PROVNO);

      if (this.mapLevel == "provinsi") {
        const achievement_percentage = vm.map_values_province[province_id];
        if (
          achievement_percentage != null ||
          achievement_percentage != undefined
        ) {
          layer.setStyle({
            color: "white",
            fillColor: vm.getLegendColor(achievement_percentage),
            fillOpacity: 1,
          });
          layer.on({
            mouseover(e) {
              const popup_content = `${province_name} <br> Capaian ${achievement_percentage} %`;
              e.target.bindPopup(popup_content).openPopup();

              e.target.setStyle({
                weight: 5,
              });
            },
          });
        }
      } else if (this.mapLevel == "kota") {
        let id = parseInt(feature.properties.KABKOTNO);
        if (id.toString().length == 1) {
          id = `0${id}`;
        }
        const regency_id = parseInt(`${province_id}${id}`);
        const regency_name = feature.properties.KABKOT;
        const achievement_percentage = vm.map_values_regencies[regency_id];

        if (
          achievement_percentage != null ||
          achievement_percentage != undefined
        ) {
          layer.setStyle({
            fillColor: vm.getLegendColor(achievement_percentage),
            fillOpacity: 1,
          });

          layer.on({
            mouseover(e) {
              const popup_content = `${regency_name} <br> Capaian ${achievement_percentage} %`;
              e.target.bindPopup(popup_content).openPopup();

              e.target.setStyle({
                weight: 5,
              });
            },
          });
        }
      }
    },
    clickable(e) {
      this.needReset = true;
      let coordinates = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      };

      if (this.dataGeo.name == "prov_indonesia") {
        this.dataGeo = kota;
        let dataProv = {
          id: e.layer.feature.properties.PROVNO,
          name: e.layer.feature.properties.PROVINSI,
        };
        this.idProv = dataProv.id;
        this.$refs.grafik.fromMap("prov", dataProv);
        this.getMapColors(dataProv.id, coordinates);
        this.mapLevel = "kota";
      } else {
        let id = e.layer.feature.properties.KABKOTNO;
        let mutateId = null;
        let finalId = null;
        if (id.toString().length > 1) {
          mutateId = id;
        } else {
          mutateId = `0${id}`;
        }
        finalId = `${this.idProv}${mutateId}`;
        let dataCity = {
          id: finalId,
          name: e.layer.feature.properties.KABKOT,
        };
        this.$refs.grafik.fromMap("city", dataCity);
        this.createMarker(finalId);
        this.dataGeo = kota;
        this.addColor("city", id);
        this.initMap(coordinates, 9, 0.5);
      }
    },
    createMarker(finalId) {
      // Check if login
      if (typeof Cookies.get("token") !== "undefined") {
        this.$store.dispatch("map/getCoor", finalId).then((data) => {
          this.showMarker = true;
          this.loopRs(data["data_RS"]);
          this.loopPuskesmas(data["data_puskesmas"]);
        });
      }
    },
    loopRs(data) {
      data.forEach((el) => {
        let arr = el.longlat.split(",");
        let coordinates = {
          lat: arr[0],
          lng: arr[1],
          id: el.profilrs_id,
        };
        if (arr[0] != "null" && arr[1] != "null") {
          this.markerRs(coordinates);
        }
      });
    },
    loopPuskesmas(data) {
      data.forEach((el) => {
        let arr = el.longlat.split(",");
        let coordinates = {
          lat: arr[0],
          lng: arr[1],
          id: el.profilpuskesmas_id,
        };
        if (arr[0] != "null" && arr[1] != "null") {
          this.markerPus(coordinates);
        }
      });
    },
    markerRs(coordinates) {
      let pin = L.icon({
        iconUrl: this.markerBlue,
        iconSize: [25, 35],
      });
      let vm = this;
      L.marker([coordinates.lat, coordinates.lng], {
        icon: pin,
        id: coordinates.id,
        fasyankes: 1,
      })
        .addTo(vm.map)
        .on("click", this.getGrafik);
    },
    markerPus(coordinates) {
      let pin = L.icon({
        iconUrl: this.markerRed,
        iconSize: [25, 35],
      });
      let vm = this;
      L.marker([coordinates.lat, coordinates.lng], {
        icon: pin,
        id: coordinates.id,
        fasyankes: 2,
      })
        .addTo(vm.map)
        .on("click", this.getGrafik);
    },
    getGrafik(e) {
      let data = {
        bulan: this.savedMonth ? this.savedMonth : this.$date().format("MM"),
        tahun: this.savedYear ? this.savedYear : this.$date().format("YYYY"),
        fasyankes: e.target.options.fasyankes,
        id: e.target.options.id,
      };
      this.$refs.grafik.setDataMarker(data);
    },
  },
};
</script>

<style scoped>
.summary_data {
  position: absolute;
  width: 350px;
  height: auto;
  right: 20px;
  top: 20px;
  z-index: 3 !important;
}

.maps {
  position: absolute;
  right: 0;
  width: 130%;
  height: 100vh;
  z-index: 1 !important;
  overflow: hidden !important; /* Add this line */
}
.absolute_search {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 20px;
  width: 75%;
  z-index: 3 !important;
}
.absolute_fade {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 30px;
  width: 75%;
  z-index: 3 !important;
}
.absolute_info_mark {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 60px;
  width: 75%;
  z-index: 3 !important;
}
.card_info_mark {
  width: 400px;
  background: rgba(255, 255, 255, 0.756);
}
.temp_input_src {
  padding: 5px;
  background: #e5f7fbcf;
  border-radius: 8px;
}
.custom_search {
  background: #fff;
  font-size: 16px !important;
  width: 200px;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}
.custom_search:focus {
  outline: none;
}
.custom_search::placeholder {
  font-size: small;
}
.temp_fade {
  width: 400px;
}
.separate {
  width: calc(100% / 5);
  position: relative;
}
.fade1 {
  background: #c4cfd9;
}
.fade2 {
  background: #dbe37b;
}
.fade3 {
  background: #96e37b;
}
.fade4 {
  background: #23ddbc;
}
.fade5 {
  background: #37bdd6;
}
.fade6 {
  background: #0092e4;
}

.absolute_p {
  margin: 0;
  position: absolute;
  right: -7px;
  color: #fff;
  text-shadow: 1px 1px 0 #000;
  font-size: small;
}
.list_reg {
  position: absolute;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 5;
}
.list_item:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.clear_input {
  margin-left: -25px;
  cursor: pointer;
}
.topMobileContainer {
  height: 256px;
  background-color: #00b4cc;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .summary_data {
    width: auto;
    position: relative;
    min-height: 770px;
    right: 0;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
