<template>
  <div v-if="$isMobile()">
    <div class="custom_tab pa-3 d-flex mx-auto">
      <div
        :class="local_tab_id === tabs.environment_health_key.id ? 'tab_items isActive pa-5' : 'tab_items pa-5'"
        @click="switchTab(tabs.environment_health_key.id, tabs.environment_health_key.key)"
      >
        <p
          class="text-center ma-0 color_default normal_lh"
          @click="switchTab(tabs.environment_health_key.id, tabs.environment_health_key.key)"
        >
          {{ tabs.environment_health_key.label }}
        </p>
      </div>
      <div
        :class="local_tab_id === tabs.waste_management.id ? 'tab_items isActive pa-5' : 'tab_items pa-5'"
        @click="switchTab(tabs.waste_management.id, tabs.waste_management.key)"
      >
        <p class="text-center ma-0 color_default normal_lh">
          {{ tabs.waste_management.label }}
        </p>
      </div>
    </div>
    <v-card class="custom_card pa-5 rounded-max my-3">
      <v-row>
        <v-col cols="5">
          <div class="py-3">
            <v-select
              v-model="selected_fasyankes_type"
              :items="fasyankes_types"
              item-text="txt"
              item-value="val"
              hide-details="auto"
              class="ma-0 pa-0"
              @change="fetchSummary"
            />
          </div>
        </v-col>
        <v-col cols="7">
          <div
            v-if="local_tab_id === 2"
            class="py-3"
          >
            <v-menu
              v-model="calendar"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  placeholder="--- ------"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date2"
                type="month"
                scrollable
                @input="menu = false"
                @change="fetchSummaryWaste(date2)"
              />
            </v-menu>
          </div>
          <div
            v-else
            class="py-3"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              :nudge-left="100"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="local_date"
                  append-icon="mdi-calendar"
                  placeholder="--- ------"
                  readonly
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="local_date"
                type="month"
                scrollable
                @input="menu = false"
                @change="fetchSummaryWaste(date)"
              />
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="custom_card pa-5 rounded-max">
      <div
        v-if="local_tab_id === tabs.environment_health_key.id"
        class="d-flex flex-column"
        style="padding-left: 12px; padding-right: 12px"
      >
        <span class="mt-1"><strong>{{ labelMap }}</strong></span>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <div class="d-flex align-center mt-4">
              <v-icon
                style="cursor: pointer;"
                v-bind="attrs"
                color="mainBlue"
                class="mr-2"
                v-on="on"
              >
                mdi-information
              </v-icon>
              <span style="color: grey; font-size: 14px">Layanan Kesehatan Lingkungan (5 Domain)</span>
            </div>
          </template>
          <div style="padding: 5px">
            <span>Layanan kesehatan lingkungan yang<br>terdiri dari domain air,
              sanitasi, higiene,<br>
              Limbah, kebersihan lingkungan.</span>
          </div>
        </v-tooltip>
      </div>
      <div
        v-if="local_tab_id === 2"
        class="d-flex flex-column"
        style="padding-left: 12px; padding-right: 12px"
      >
        <span class="mt-1"><strong class="text-uppercase">{{ labelMap }}</strong></span>
      </div>

      <div>
        <div v-if="local_tab_id === tabs.environment_health_key.id">
          <div
            v-if="summary && summary.data.length > 0"
            :key="0"
          >
            <v-col
              v-if="summary.data[0]"
              :key="0"
            >
              <GraphicHomeDonutEnvironmentalHealthKey
                v-if="summary.data[0]"
                :summary-data="summary"
                :series="generateSeries(summary.data[0].data)"
                :province_name="getProvinceById(selected_province_id)"
                :regency_name="getRegencyById(selected_regency_id)"
                :date="local_date"
              />
            </v-col>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 200px"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            />
            <b class="color_default">Loading...</b>
          </div>
        </div>

        <div v-if="local_tab_id === 2">
          <v-col cols="6">
            <v-select
              v-model="selectedLabel"
              class="ma-0 pa-0"
              :items="summary_waste.data.map(item => item.label)"
              item-text="txt"
              item-value="val"
              @change="fetchSummaryWaste"
            />
          </v-col>
          <v-col>
            <GraphicHomeDonutWaste
              v-for="(item, index) in filteredData"
              :key="index"
              :selected-label="selectedLabel"
              :summary-waste="summary_waste"
              :type="item.type"
              :series="generateSeries(item.data)"
              :province-name="getProvinceById(selected_province_id)"
              :regency-name="getRegencyById(selected_regency_id)"
              :date="local_date"
            />
          </v-col>
          <div>
            <div v-if="summary_waste.data.length === 0">
              <!-- Loading indicator -->
              <div
                class="d-flex justify-center align-center"
                style="height: 200px"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="#00b4cc"
                />
                <b class="color_default">Loading...</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>

  <div v-else>
    <div class="custom_tab pa-2 d-flex">
      <div
        :class="local_tab_id === tabs.environment_health_key.id ? 'tab_items isActive pa-2' : 'tab_items pa-2'"
        @click="switchTab(tabs.environment_health_key.id, tabs.environment_health_key.key)"
      >
        <p
          class="text-center ma-0 color_default small_txt normal_lh"
          @click="switchTab(tabs.environment_health_key.id, tabs.environment_health_key.key)"
        >
          {{ tabs.environment_health_key.label }}
        </p>
      </div>
      <div
        :class="local_tab_id === tabs.waste_management.id ? 'tab_items isActive pa-2' : 'tab_items pa-2'"
        @click="switchTab(tabs.waste_management.id, tabs.waste_management.key)"
      >
        <p class="text-center ma-0 color_default small_txt normal_lh">
          {{ tabs.waste_management.label }}
        </p>
      </div>
    </div>

    <v-card class="custom_card pa-2 rounded-max my-3">
      <v-row>
        <v-col cols="6">
          <div class="py-2">
            <v-select
              v-model="selected_fasyankes_type"
              :items="fasyankes_types"
              item-text="txt"
              item-value="val"
              hide-details="auto"
              class="ma-0 pa-0"
              @change="fetchSummaryWaste"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div
            v-if="local_tab_id === tabs.waste_management.id"
            class="py-2"
          >
            <v-menu
              v-model="calendar"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="200px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  placeholder="--- ------"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date2"
                type="month"
                scrollable
                @input="menu = false"
                @change="fetchSummaryWaste(date2)"
              />
            </v-menu>
          </div>
          <div
            v-else
            class="py-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              :nudge-left="100"
              offset-y
              max-width="300px"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="local_date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  hide-details
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="local_date"
                type="month"
                scrollable
                @input="menu = false"
              >
                <v-btn
                  text
                  color="primary"
                  @change="getChartKeslingKunci(date)"
                />
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="custom_card pa-2 rounded-max">
      <div
        v-if="local_tab_id === tabs.environment_health_key.id"
        class="d-flex flex-column"
        style="padding-left: 12px; padding-right: 12px"
      >
        <span
          style="font-size: 13px;"
          class="mt-1"
        ><strong class="text-uppercase">{{ labelMap }}</strong></span>
        <v-tooltip
          color="white"
          max-width="300px"
          nudge-top="10px"
          nudge-left="20px"
          left
        >
          <template #activator="{ on, attrs }">
            <div class="d-flex align-center mt-1">
              <v-icon
                style="cursor: pointer;"
                v-bind="attrs"
                color="mainBlue"
                class="mr-2"
                v-on="on"
              >
                mdi-information
              </v-icon>
              <span style="color: grey; font-size: 12px">Layanan Kesehatan Lingkungan (5 Domain)</span>
            </div>
          </template>
          <div style="padding: 5px">
            <span class="small_txt d-flex align-center color_txt">Layanan kesehatan lingkungan yang<br>terdiri dari domain air,
              sanitasi, higiene,<br>
              Limbah, kebersihan lingkungan.</span>
          </div>
        </v-tooltip>
      </div>
      <div
        v-if="local_tab_id === tabs.waste_management.id"
        class="d-flex flex-column"
        style="padding-left: 12px; padding-right: 12px"
      >
        <span
          style="font-size: 13px;"
          class="mt-1"
        ><strong class="text-uppercase">{{ labelMap }}</strong></span>
      </div>

      <div>
        <div v-if="local_tab_id === tabs.environment_health_key.id">
          <div
            v-if="summary.data && summary.data.length > 0"
            :key="0"
          >
            <v-col
              v-if="summary.data[0]"
              :key="0"
            >
              <GraphicHomeDonutEnvironmentalHealthKey
                v-if="summary.data[0]"
                :summary-data="summary"
                :series="generateSeries(summary.data[0].data)"
                :province_name="getProvinceById(selected_province_id)"
                :regency_name="getRegencyById(selected_regency_id)"
                :date="local_date"
              />
            </v-col>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
            style="height: 200px"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            />
            <b class="color_default">Loading...</b>
          </div>
        </div>

        <div v-if="local_tab_id === 2">
          <v-col
            cols="7"
            style="padding-top: 2px !important; padding-bottom: 0px !important;"
          >
            <v-select
              v-model="selectedLabel"
              class="ma-0 pa-0"
              :items="summary_waste && summary_waste.data ? summary_waste.data.map(item => item.label) : []"
              item-text="txt"
              item-value="val"
              @change="fetchSummaryWaste"
            />
          </v-col>
          <v-col>
            <GraphicHomeDonutWaste
              v-for="(item, index) in filteredData "
              :key="index"
              :selected-label="selectedLabel"
              :summary-waste="summary_waste"
              :type="item.type"
              :series="generateSeries(item.data)"
              :province-name="getProvinceById(selected_province_id)"
              :regency-name="getRegencyById(selected_regency_id)"
              :date="local_date"
            />
          </v-col>
          <div>
            <div v-if="summary_waste.data.length === 0">
              <!-- Loading indicator -->
              <div
                class="d-flex justify-center align-center"
                style="height: 200px"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="#00b4cc"
                />
                <b class="color_default">Loading...</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import { ROLES } from "../../../constants/constant";
import GraphicHomeDonutEnvironmentalHealthKey from "../../../components/Graphic/EnvironmentalHealthKey/HomeDonut.vue";
import GraphicHomeDonutWaste from "../../../components/Graphic/Waste/HomeDonut.vue";

const tabs = {
  environment_health_key: {
    id: 1,
    key: 'kesehatan-lingkungan',
    label: "Kesling Kunci",
  },
  waste_management: {
    id: 2,
    key: 'limbah-fasyankes',
    label: "Kelola Limbah",
  },
};
  
export default {
  name: "GrafikBeranda",
  components: {
    GraphicHomeDonutEnvironmentalHealthKey,
    GraphicHomeDonutWaste,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    tab_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      local_date: this.date,
      tabs,
      fasyankes_types: [
        {
          txt: "Fasyankes",
          val: "",
        },
        {
          txt: "Rumah Sakit",
          val: 1,
        },
        {
          txt: "Puskesmas",
          val: 2,
        },
      ],
      ROLES,
      is_loading: false,
      provinces: [],
      cities: [],
      selectedLabel: "Pengelolaan",
      selected_province_id: null,
      selected_regency_id: null,
      search_query: "",
      page: 1,
      limit: 10,
      menu_date: false,
      menu_fasyankes_type: false,
      selected_fasyankes_type: "",
      isMobile: false,
      selectedFasyankes: "pengelolaan",
      selectedKesling: "air",
      selectedMerkuri: "penghapusan",
      local_tab_id: this.tab_id,
      noData: false,
      labelMap: "Nasional",
      prov_id: "",
      city_id: "",
      calendar: false,
      menu: false,
      date2: this.$date().format("YYYY-MM"),
      itemFasyankes: [
        {
          txt: "Pengelolaan",
          val: "pengelolaan",
        },
        {
          txt: "Pemilahan",
          val: "pemilahan",
        },
        {
          txt: "Pewadahan",
          val: "pewadahan",
        },
        {
          txt: "Pengangkutan",
          val: "pengangkutan",
        },
        {
          txt: "Penyimpanan",
          val: "penyimpanan",
        },
        {
          txt: "Pengolahan",
          val: "pengolahan",
        },
      ],
      itemKesling: [
        {
          txt: "Air",
          val: "air",
        },
        {
          txt: "Sanitasi",
          val: "sanitasi",
        },
        {
          txt: "Higiene",
          val: "higiene",
        },
        {
          txt: "Limbah",
          val: "limbah",
        },
        {
          txt: "Kebersihan Lingkungan",
          val: "kesling",
        },
      ],

      configFasyankes: null,
      configCovid: null,
      configMerkuri: null,
      configKesling: null,
      clickMarker: false,
      dataMarker: null,
      markerFasyankes: null,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      summary: (state) => state.report.monthly_summary,
      summary_waste: (state) => state.report.waste_monthly_summary,
    }),
    filteredData() {
      if (!this.summary_waste || !this.summary_waste.data) {
        return [];
      }
      return this.summary_waste.data.filter(
        (item) => item.label === this.selectedLabel
      );
    },
  },
  watch: {
    selected_province_id() {
      if (
        this.selected_province_id &&
        this.selected_province_id !== "" &&
        this.role !== ROLES.PublicHealthService_Regency
      ) {
        this.selected_regency_id = null;
      }
    },
    selected_fasyankes_type(value) {
      if (this.local_tab_id === this.tabs.environment_health_key.id) {
        this.fetchSummary()
      } else if (this.local_tab_id === this.tabs.waste_management.id) {
        this.fetchSummaryWaste()
      }
    },
    local_date() {
      this.$emit('update:date', this.local_date)
    },
    local_tab_id() {
      this.$emit('update:tab_id', this.local_tab_id)
    },
  },
  async mounted() {
    await this.getProvinces();
    await this.fetchSummary();
    await this.fetchSummaryWaste();
  },
  methods: {
    async fetchSummary() {
      this.loading = true;
      let request_params = {
        date: this.date,
        fasyankes_type: this.selected_fasyankes_type,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
      };
      this.summaryData = await this.$store.dispatch(
        "report/getMonthlySummary",
        request_params
      );
      this.loading = false;
    },
    async fetchSummaryWaste() {
      this.loading = true;
      let request_params = {
        date: this.date,
        fasyankes_type: this.selected_fasyankes_type,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
      };
      await this.$store.dispatch(
        "report/getWasteManagementMonthlySummary",
        request_params
      );
      this.loading = false;
    },
    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;

        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = {
        provinsi_id: this.selected_province_id,
      };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
        }
        this.cities = cities;
      });
    },
    generateSeries(data) {
      const { meet_criteria, not_meet_criteria } = data;
      return [meet_criteria, not_meet_criteria];
    },
    getProvinceById(id) {
      if (!id) return "";
      const province = this.provinces.find((item) => item.provinsi_id === id);
      return province?.provinsi_name;
    },
    getRegencyById(id) {
      if (!id) return "";
      const regency = this.cities.find((item) => item.kabupatenKota_id === id);
      return regency?.kabupaten_name;
    },
    switchTab(tab_id) {
      if (tab_id === 1) {
        this.fetchSummary();
      } else if (tab_id === 2) {
        this.fetchSummaryWaste();
      }
      this.local_tab_id = tab_id;
      this.$emit("changeTab", tab_id);
    },
    resetGrafik() {
      this.prov_id = "";
      this.city_id = "";
      this.labelMap = "Nasional";
      this.fromMap("prov", {
        id: "",
        name: "Nasional",
      });
      this.clickMarker = false;
    },
    setDataMarker(data) {
      this.dataMarker = data;
      this.marker();
      this.getName(data);
    },
    marker() {
      this.clickMarker = true;
      let tipe = null;
      tipe = this.selectedFasyankes;
      this.dataMarker["tipe"] = tipe;
      this.$store
        .dispatch("map/getFromMarker", this.dataMarker)
        .then((data) => {
          if (tipe == "pemilahan") {
            if (data.chart.Detail.length) {
              let mutate = data.chart["Pemilahan Standar"];
              let tempArr = [];
              let newObj = {};
              mutate.forEach((el) => {
                newObj[
                  el.datapenyusun_name.replaceAll(/ /g, "_").toLowerCase()
                ] = el.datapenyusun_desc;
                tempArr.push(el.datapenyusun_desc);
              });
              let total = tempArr.reduce((a, b) => a + b, 0);
              let infeksius =
                newObj.pemilahan_limbah_medis_infeksius +
                newObj.pemilahan_limbah_medis_tajam +
                newObj._pemilahan_limbah_medis_patologi;
              let noninfeksius =
                newObj.pemilahan_limbah_medis_farmasi +
                newObj.pemilahan_limbah_medis_kimia +
                newObj.pemilahan_limbah_medis_sitotoksik +
                newObj.pemilahan_limbah_medis_radioaktif;
              newObj["infeksius"] = infeksius;
              newObj["noninfeksius"] = noninfeksius;
              newObj["medis"] = infeksius + noninfeksius;
              newObj["total"] = total;

              this.markerFasyankes = newObj;
            } else {
              this.markerFasyankes = null;
            }
          } else if (
            tipe == "pewadahan" ||
            tipe == "pengangkutan" ||
            tipe == "penyimpanan" ||
            tipe == "pengolahan"
          ) {
            let mutate = data.chart.Detail;
            this.markerFasyankes = mutate;
          } else if (tipe == "pengelolaan") {
            let mutate = [];
            let param = [];
            for (let i in data) {
              if (i !== "message") {
                param.push(data[i]);
                mutate.push({
                  key: i,
                  label: data[i] == "Nonstandar" ? 2 : 1,
                });
              }
            }
            let msg = "";
            let isStandar = param.includes("Nonstandar");
            if (isStandar) {
              msg = "Belum memenuhi standar pengelolaan limbah";
            } else {
              msg = "Sudah memenuhi standar pengelolaan limbah";
            }
            this.markerFasyankes = {
              data: mutate,
              msg: msg,
            };
          }
        });
    },
    getName(data) {
      this.$store.dispatch("map/getNameRs", data).then((res) => {
        if (data.fasyankes == 1) {
          this.labelMap = res.data[0].nama_rs;
        } else {
          this.labelMap = res.data[0].namapuskesmas;
        }
      });
    },
    fromMap(type, data) {
      this.labelMap = data.name;
      if (type == 'prov') {
        this.selected_province_id = data.id;
      } else if (type == 'city') {
        this.selected_regency_id = data.id;
      }

      if (this.local_tab_id === tabs.environment_health_key.id) {
        this.fetchSummary();
      } else {
        this.fetchSummaryWaste();
      }
    },
  },
};
</script>

<style scoped>
.custom_tab {
  width: 100%;
  border-radius: 10px;
  background: #e5f7fbba;
  z-index: 4 !important;
}
.isActive {
  border-radius: 8px;
  background: #fff;
  opacity: 1;
}
.tab_items {
  width: calc(100% / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  font-weight: 600;
}
.arrow_box {
  position: relative;
  z-index: 2;
}

#apexchartsdonut .apexcharts-tooltip {
  transform: translateX(10px) translateY(0);
  overflow: visible !important;
  white-space: normal !important;
}

#apexchartsdonut .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}
.item_pm {
  width: 60%;
}
.val_pm {
  width: 20%;
}
.satuan_pm {
  width: 20%;
}
.total-limbah {
  /* margin-top: -100px; */
  margin-top: 55px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}
</style>
